/* Base Styles */
.tracking-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #0056b3;
}

.instructions {
  font-size: 1rem;
  line-height: 1.6;
}

.instructions h2 {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  margin: 10px 0;
}

.instructions ol {
  margin: 15px 0;
  padding-left: 20px;
}

.instructions ol li {
  margin: 10px 0;
}

.instructions ul {
  margin-left: 20px;
  list-style: disc;
}

.instructions a {
  color: #007bff;
  text-decoration: none;
}

.instructions a:hover {
  text-decoration: underline;
}

/* Responsiveness */
@media (max-width: 768px) {
  .tracking-page {
    padding: 15px;
    margin: 10px;
  }

  .title {
    font-size: 1.5rem;
  }

  .instructions h2 {
    font-size: 1.3rem;
  }

  .instructions {
    font-size: 0.95rem;
  }

  .instructions ol li {
    margin: 8px 0;
  }
}

@media (max-width: 480px) {
  .tracking-page {
    padding: 10px;
  }

  .title {
    font-size: 1.2rem;
  }

  .instructions h2 {
    font-size: 1.1rem;
  }

  .instructions {
    font-size: 0.9rem;
  }

  .instructions ol {
    padding-left: 15px;
  }
}
