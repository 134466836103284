.contact-container {
  max-width: 800px;
  margin: 20px auto; /* Center the container and add top margin */
  padding: 30px; /* Increased padding for better spacing */
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow */
  background-color: #f9f9f9; /* Light background color for contrast */
  color: black;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 25px; /* Increased gap between sections */
  color: black;
}

.contact-section {
  margin-bottom: 25px; /* Slightly more spacing at the bottom */
  color: black;
}

.contact-section h4 {
  margin-bottom: 20px; /* Increased spacing below the header */
  font-size: 22px; /* Slightly larger font size for better readability */
  font-weight: bold;
  color: #333; /* Darker shade for the heading */
}

.contact-section p {
  margin: 8px 0; /* Increased vertical spacing */
  font-size: 17px; /* Slightly larger text for better readability */
  line-height: 1.6; /* Improved line spacing for readability */
  color: #555; /* Softer black for paragraph text */
}

.contact-icons {
  display: flex;
  gap: 15px; /* Increased space between icons */
}

.contact-icons a {
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for both transform and color */
  color: #555; /* Softer black for icons */
}

.contact-icons a:hover {
  transform: scale(1.15); /* Slightly larger scaling on hover */
  color: #000; /* Change color on hover */
}

.text-center {
  text-align: center;
  color: black;
}

.me-2 {
  margin-right: 0.5rem;
}

.me-3 {
  margin-right: 1rem;
}

.me-4 {
  margin-right: 1.5rem;
}
