.shop-by-customize {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Libre Baskerville", serif;
}
.shop-by-customize-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-wrap: nowrap;
  font-weight: 700;
  font-size: 24px;
}
.divider-left, .divider-right {
  height: 5px;
  background-color: #9C4A1A;
}

.divider-left {
  width: 40%;
}

.divider-right {
  width: 35%;
}
.swiper-container.customize-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.image-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.customize-type {
  padding: 20px 0;
}

.swiper-slide {
  text-align: center;
  flex: 1 0 200px;
  margin: 10px;
}

.customize-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customize-item img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.customize-item div {
  font-size: 16px;
  color: #333;
}
.swiper-wrapper {
  width: fit-content;
  width: -moz-fit-content;
}
.shop-customize-carousel {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Libre Baskerville", serif;
}
@media (max-width: 1100px) {
  .shop-by-customize-header {
    font-size: 24px;
  }

  .divider-left, .divider-right {
    height: 5px;
  }

  .divider-left {
    width: 35%;
  }

  .divider-right {
    width: 30%;
  }
}
@media only screen and (max-width: 768px) {
  .shop-by-customize-header {
    font-size: 18px;
  }

  .divider-left, .divider-right {
    height: 5px;
  }

  .divider-left {
    width: 35%;
  }

  .divider-right {
    width: 30%;
  }

  
}

@media (max-width: 480px) {
  .shop-by-customize-header {
    font-size: 14px;
  }

  .divider-left, .divider-right {
    height: 5px;
  }

  .divider-left {
    width: 29%;
  }

  .divider-right {
    width: 24%;
  }
}

