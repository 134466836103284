.wishlist-page {
  padding: 20px;
}

.wishlist-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.wishlist-item {
  border: 1px solid #cf7d08;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: calc(33.333% - 40px);
  max-width: 280px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wishlist-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.add-to-cart-button,
.remove-from-wishlist-button {
  padding: 3px 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #cf7d08;
  border: none;
  border-radius: 4px;
  color: white;
  margin: 5px 0;
}

.add-to-cart-button {
  margin-right: 10px;
  background-color: #cf7d08;
}

@media (max-width: 1200px) {
  .wishlist-item {
    width: calc(33.333% - 30px);
  }
}

@media (max-width: 992px) {
  .wishlist-item {
    width: calc(50% - 30px);
  }
}

@media (max-width: 768px) {
  .wishlist-item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .wishlist-item {
    width: calc(100% - 20px);
  }
}
