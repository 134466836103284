.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.preview-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.image-preview,
.video-preview {
  position: relative;
}

.clear-preview-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.preview-image,
.preview-video {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.upload-button {
  margin-top: 1rem;
}

.submit-button {
  margin-top: 2rem;
}
