.shop-page {
  padding: 20px;
}

.shop-content {
  max-width: 1200px;
  margin: 0 auto;
}
.sorter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-family: "Libre Baskerville", serif;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-item {
  flex: 1 1 calc(33.333% - 20px);
  box-shadow: 0 2px 5px rgba(21, 1, 1, 0.1);
  padding: 20px;
  text-align: center;
  display: inline-table;
  background-color: #f7f3ef;
}

.product-item img {
  max-width: 100%;
  height: 300px;
  mix-blend-mode: darken;
}

.product-item h2 {
  font-size: 1.1rem;
  margin: 10px 0;
  font-family: "Libre Baskerville", serif;
}

.product-item p {
  font-size: 1rem;
  color: #000000;
}

.product-item button {
  background-color: #cf7d08;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.product-item button:hover {
  background-color: #b96906;
}
.sort-button {
  background-color: #b96906;
  border: none !important;
  outline: none !important;
}
.sort-button:hover {
  outline: none !important;
  border: none !important;
}

.wishlist-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 15px;
}
.add-to-cart-button {
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}
.sort-filter {
  display: inline;
  align-items: center;
  margin-right: 0;
}

.sort-filter label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.sort-filter select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease;
}

.sort-filter select:hover {
  border-color: #bbb;
}
