.feature-products {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Libre Baskerville", serif;
}

.feature-product-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-wrap: nowrap;
  font-weight: 700;
  font-size: 24px;
  font-family: "Libre Baskerville", serif;
}
.divider-leftF, .divider-rightF {
  height: 5px;
  background-color: #9C4A1A;
}

.divider-leftF {
  width: 32%;
}

.divider-rightF {
  width: 30%;
}

.feature-product-carousel {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Libre Baskerville", serif;
}
@media (max-width: 1100px) {
  .feature-product-heading {
    font-size: 24px;
  }

  .divider-leftF, .divider-rightF {
    height: 5px;
  }

  .divider-leftF {
    width: 24%;
  }

  .divider-rightF {
    width: 18%;
  }
}
@media only screen and (max-width: 768px) {
  .feature-product-heading {
    font-size: 18px;
  }

  .divider-leftF, .divider-rightF {
    height: 5px;
  }

  .divider-leftF {
    width: 26%;
  }

  .divider-rightF {
    width: 24%;
  }

  
}

@media (max-width: 480px) {
  .feature-product-heading {
    font-size: 14px;
  }

  .divider-leftF, .divider-rightF {
    height: 5px;
  }

  .divider-leftF {
    width: 16%;
  }

  .divider-rightF {
    width: 14%;
  }
}

