.product-detail {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: "Libre Baskerville", serif;
}
.comment {
  color: #000;
}
.product-images {
  display: grid;
  flex: 2;
  gap: 10px;
}

.product-info {
  flex: 1;
  padding-left: 20px;
}

.sizes {
  margin-top: 10px;
}

.sizes button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.price {
  font-size: 1.2em;
  margin-top: 10px;
}

.description {
  margin-top: 20px;
  font-family: "Libre Baskerville", serif;
}

.MuiButton-root {
  margin-top: 10px;
}

.MuiButton-root.wishlist-button {
  margin-left: 10px;
}
.reviews {
  display: flex;
  align-items: center;
}

.rating-info {
  display: flex;
  align-items: center;
}

.rating-info .rating-value {
  margin-left: 8px;
}

.reviewers-info {
  margin-left: 8px;
  color: #777;
}

.description {
  margin-top: 20px;
  color: #000;
}

.btn-size {
  border: 1px solid #9c4a1a;
  border-radius: 20px;
  background-color: #f7f3ef;
}
.selected {
  background-color: #cf7d08 !important;
}
.original-price {
  color: black; /* Default color for original price */
}

.original-price.discounted {
  text-decoration: line-through; /* Red line-through for discounted price */
}

.discounted-price {
  color: black; /* Color for discounted price */
}

.discount-value {
  color: red; /* Color for discount value */
}


.quantity-selector {
  background-color: #cf7d08;
  border: 1px solid #9c4a1a;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  max-width: 100px;
  max-height: 200px;
  height: 30px;
  align-items: center;
}

.quantity-selector > button,
.quantity-selector > span {
  border: none;
  margin: auto;
  background: transparent;
  padding: 2px;
}

.product-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-right: 20px;
}
.action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product-images img {
  width: 310px;
  height: 310px;
  object-fit: cover;
}
/* @media (max-width: 1211px) {
  .product-images > img {
    display: none;
  }
} */
.product-carousel-wrapper {
  width: 100%;
  margin:  auto;
  padding: auto;
  height: 350px;
  overflow: hidden; /* Ensures the image stays within the container */
  position: relative; /* Allows positioning of carousel controls */
}

.product-carousel {
  display: flex;
  width: 100%;
  height: 100%; /* Ensure carousel takes full height */
  object-fit: cover; 
 
}

.carousel-image {
  width: 100%;
  height: 100%; /* Matches the height of the carousel container */
  object-fit: fill; /* Ensure image covers the container while maintaining aspect ratio */
  display: block;
  margin-left: -170px;
  margin-bottom: 5px; /* Ensures image is displayed as a block element */
}

@media (max-width: 1000px) {
  .product-carousel-wrapper {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    overflow: fit; /* Keeps the image within the container */
    position: relative; /* Allows positioning of carousel controls */
  }

  .carousel-image {
    width: auto;
    height: 350px; /* Matches the height of the carousel container */
    object-fit: cover; /* Ensure image covers the container while maintaining aspect ratio */
  }

  /* .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    width: 50px;
    height: 50px;
    z-index: 5;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   
  }

  .carousel-control-prev {
    left: -0px; 
  }

  .carousel-control-next {
    right: -0px; 
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(100%);
  } */
}
