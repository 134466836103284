@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");

.banner-carousel {
  justify-content: center;
  align-items: center;
  background-color: #9c4a1a;
  height: 475px; 
  position: relative; 
  overflow: hidden; 
}

.carousel-inner {
  width: 100%;
  height: 100%; 
}

.carousel-item-content {
  display: flex;
  flex-direction: row; 
  width: 100%;
  height: 475px; 
  position: relative; 
  transform: translateX(-50%); 
  margin: auto;
}

.carousel-text {
  flex: 1;
  padding: 2vw; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Libre Baskerville", serif;
  position: relative;
  margin-top: -40px;
}

.carousel-text h2 {
  font-size: 5vw; 
  font-weight: 700;
  margin-bottom: 1vw;
  color: hsl(204, 26%, 96%);
}

.carousel-text p {
  font-size: 3vw; 
  font-weight: 400;
  color: hsl(204, 26%, 96%);
}
.subscribe-button {
  background-color: white;
  color: #9c4a1a;
  border: none;
  border-radius: 50px;
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  position: relative;
  width: auto;
  height: 100%; 
  max-width: 150px;
  max-height: 56px;
}

.carousel-image {
  flex: 1;
  width: calc(100% - 30px); 
  height: 100%;
  overflow: hidden;
  margin: 0 15px;
}

.carousel-image img {
  width: 100%;
  height: 100%; 
  object-fit: cover; 
  display: fill;
}


.banner-action {
  height: 5vh; 
  width: auto;
  margin: 2vh 2vw;
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5vw; 
  height: 5vw;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  filter: invert(100%);
  opacity: 15%;
}

/* .carousel-control-prev {
  left: 2vw;
  opacity: 10%;
}

.carousel-control-next {
  right: 2vw;
  opacity: 10%;
} */

/* .carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none; 
  
} */

/* .carousel-control-prev-icon::after {
  content: '‹'; 
  font-size: 2vw; 
  
  
}

.carousel-control-next-icon::after {
  content: '›'; 
  font-size: 2vw; 
} */


@media (max-width: 1000px) {
  .carousel-item-content {
    flex-direction: column; 
  }

  .carousel-text h2 {
    margin-top: 30px;
    font-size: 7vw; 
  }

  .carousel-text p {
    margin-top: 10px;
    font-size: 6.5vw; 
  }

  .carousel-image {
    width: calc(100% - 30px); 
    margin: 0 15px; 
  }
}

@media (max-width: 768px) {
  .carousel-text h2 {
    margin-top: 30px;
    font-size: 7vw; 
  }

  .carousel-text p {
    margin-top: 10px;
    font-size: 6.5vw;  
  }

  .carousel-image {
    width: calc(100% - 20px); 
     margin: 0 5px; 
  }
  .subscribe-button {
    width: 80%; 
    font-size: 12px; 
    padding: 6px 15px; 
    max-width: 120px; 
    height: 80%;
    max-height: 48px;
  }
}

@media (max-width: 576px) {
  .carousel-text h2 {
    margin-top: 30px;
    font-size: 7vw; 
  }

  .carousel-text p {
    margin-top: 10px;
    font-size: 6.5vw; 
  }

  .carousel-image {
    width: calc(100% - 10px); 
    margin: 0 5px; 
    
  }
  .subscribe-button {
    width: 100%; 
    font-size: 10px; 
    padding: 4px 10px; 
    max-width: 100px; 
    height: 36px;
    max-height: 36px;
  }
}
