.shop-by-concern {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Libre Baskerville", serif;
}

.shop-by-concern-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
}
.divider-leftC, .divider-rightC {
  height: 5px;
  background-color: #9C4A1A;
}

.divider-leftC {
  width: 33%;
}

.divider-rightC {
  width: 28%;
}
.concernSwiper {
  margin-top: 20px;
}

.carousel-concern-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.concern-product-image img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.concern-product-name {
  font-size: 16px;
  color: #333;
}

.shop-concern-carousel {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Libre Baskerville", serif;
}
@media (max-width: 1100px) {
  .shop-by-concern-header {
    font-size: 24px;
  }

  .divider-leftC, .divider-rightC {
    height: 5px;
  }

  .divider-leftC {
    width: 25%;
  }

  .divider-rightC {
    width: 17%;
  }
}
@media only screen and (max-width: 768px) {
  .shop-by-concern-header {
    font-size: 18px;
  }

  .divider-leftC, .divider-rightC {
    height: 5px;
  }

  .divider-leftC {
    width: 28%;
  }

  .divider-rightC {
    width: 20%;
  }

  .concern-product-image img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 480px) {
  .shop-by-concern-header {
    font-size: 14px;
  }

  .divider-leftC, .divider-rightC {
    height: 5px;
  }

  .divider-leftC {
    width: 16%;
  }

  .divider-rightC {
    width: 12%;
  }
}
@media (max-width: 370px) {
  .shop-by-concern-header {
    font-size: 14px;
  }

  .divider-leftC, .divider-rightC {
    height: 5px;
  }

  .divider-leftC {
    width: 9%;
  }

  .divider-rightC {
    width: 7%;
  }
}