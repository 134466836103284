@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
  font-family: 'Montserrat', Poppins, Gilroy;
}
.footer-top-text {
  font-size: 18px;
  font-weight: 700;
}
a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: rgb(250, 245, 245);
}
.links {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #111;
}
p {
  color: rgb(237, 231, 231);
}
h6 {
  color: white;
}
/* .contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact-item .mdb-icon {
  margin-right: 0.5rem;
} */

@media (max-width: 576px) {
  .section-80 {
    width: 80%;
    margin: 0 auto; /* Center the section */
  }
}