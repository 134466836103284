.MainHeader {
  margin-left: 55px;
  margin-right: 55px;
  margin-bottom: -26px;
}

.search-row {
  border-radius: 20px;
  padding: 4px 14px 8px 14px;
  color: black;
  background-color: white;
  display: flex;
  align-items: center;
  border: 2px solid rgb(197, 140, 49);
  width: 100%;
  /* max-width: fit-content; */
}

.custom-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: none;
}

.custom-dropdown-item {
  padding: 8px 20px;
}

.nav-content {
  display: flex;
  align-items: center;
}

.icon-text {
  margin-left: auto;
  font-weight: inherit;
  font-family: "Libre Baskerville", serif;
  cursor: pointer;
  align-self: inherit;
  font-size: 20px;
  font-weight: bold;
}
.icon-text2 {
  margin-left: auto;
  font-weight: inherit;
  font-family: "Libre Baskerville", serif;
  cursor: pointer;
  align-self: inherit;
  font-size: 20px;
  font-weight: bold;
  background-color: #9c4a1a;
  color: white;
  border: none;
  padding: 8px 12px;
  font-family: "Libre Baskerville", serif;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.form-control {
  border: none;
  box-shadow: none;
  outline: none;
}
.form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.dropdown-menu-list {
  position: absolute !important;
  margin-top: 50px !important;
  margin-left: 20px !important;
}

.dropdown-menu-list2 {
  position: absolute !important;
  margin-top: 50px !important;
  margin-left: 20px !important;
}

@media only screen and (min-width: 950px) {
  .dropdown-menu-list2 {
    margin-left: -80px !important;
  }
}

@media (max-width: 768px) {
  .nav-bar-custom .navbar-collapse {
    display: flexbox;
    flex-direction: column;
  }
  .MainHeader {
    margin-left: 24px;
    margin-right: 3px;
    margin-bottom: -29px;
  }

  .search-row {
    width: 100%;
  }

  .dropdown-menu-list,
  .dropdown-menu-list2 {
    margin-left: 0 !important;
  }
  .icon-text {
    margin-left: auto;
    font-weight: inherit;
    font-family: "Libre Baskerville", serif;
    cursor: pointer;
    align-self: inherit;
    font-size: 10px;
    font-weight: bold;
  }
  .icon-text2 {
    margin-left: auto;
    font-weight: inherit;
    font-family: "Libre Baskerville", serif;
    cursor: pointer;
    align-self: inherit;
    font-size: 10px;
    font-weight: bold;
  }
}

header {
  width: 100%;
  height: 80px;
  margin: auto;
  padding: 15px 0px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.headerSearch {
  width: 100%;
  height: 60px;
  border: 1px solid #120f0c;
}
.header-row {
  display: flex;
  flex-direction: row;
  height: min-content;
  align-items: center;
  padding: 2px;
}
.logo-row {
  padding-left: 10px;
  margin-left: 10px;
}

.search-row > input {
  border: none;
  background: none;
  color: #000000;
  font-weight: bold;
  font-size: smaller;
  font-family: "Libre Baskerville", serif;
}

.search-row > input:focus,
.search-row > input:active {
  border-style: none;
  outline: none;
}
.btn-categories {
  border: none;
  background: none;
  font-size: smaller;
  font-weight: bold;
  padding: 2px;
}
.header-row-last {
  font-family: "Libre Baskerville", serif;
  margin-right: 30px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  flex-grow: inherit;
}
.header-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 50px;
  background-color: none;
}
.icon-text {
  align-self: self-end;
  font-family: "Libre Baskerville", serif;
}
.cart-count {
  margin-bottom: 20px;
  font-weight: 500;
  background-color: #cf7d08;
  color: aliceblue;
  border-radius: 50%;
  padding: 4px;
  width: 26px;
  height: 26px;
  margin-left: -10px;
  font-size: small;
  font-family: "Libre Baskerville", serif;
}
.icons-header {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}
.custom-dropdown-item:hover {
  background-color: #cf7d08 !important;
  color: white !important;
}
.dropdown-menu.show {
  top: 0px !important;
  left: -15px !important;
}
.CollapsableDrawer {
  display: none;
  background: none;
  border: none;
  width: 30%;
}
.header-link {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  color: #000000;
  font-weight: bolder;
  font-family: "Libre Baskerville", serif;
}
.nav-content-drawer {
  display: none;
}

header {
  width: 100%;
  height: 80px;
  margin: auto;
  padding: 15px 0px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.headerSearch {
  width: 100%;
  height: 60px;
  border: 1px solid #120f0c;
}
.header-row {
  display: flex;
  flex-direction: row;
  height: min-content;
  align-items: center;
  padding: 2px;
}
.logo-row {
  padding-left: 10px;
  margin-left: 10px;
}

.search-row > input {
  border: none;
  background: none;
  color: #000000;
  font-weight: bold;
  font-size: smaller;
}

.search-row > input:focus,
.search-row > input:active {
  border-style: none;
  outline: none;
}
.btn-categories {
  border: none;
  background: none;
  font-size: smaller;
  font-weight: bold;
  padding: 2px;
}
.header-row-last {
  font-family: "Libre Baskerville", serif;
  margin-right: 30px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  flex-grow: inherit;
}
.header-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 50px;
  background-color: none;
}
.icon-text {
  align-self: self-end;
}
.icons-header {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}
.custom-dropdown-item:hover {
  background-color: #cf7d08 !important;
  color: white !important;
}
.dropdown-menu.show {
  top: 0px !important;
  left: -15px !important;
}
.CollapsableDrawer {
  display: none;
  background: none;
  border: none;
  width: 30%;
}
.header-link {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  color: #000000;
  font-weight: bolder;
  font-family: "Libre Baskerville", serif;
}

@media only screen and (max-width: 1285px) {
  .CollapsableDrawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .header-list {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    position: absolute;
    margin-top: 60px;
    background-color: white;
    /* width: fit-content; */
    place-self: flex-end;
  }
  .header-row-last {
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  .icons-header {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    cursor: pointer;
  }
  .icons-header:hover {
    background-color: #cf7d08;
  }
}
@media only screen and (max-width: 768px) {
  .header-row {
    flex-direction: column;
    padding: 10px;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }
  .icon-text {
    font-size: 15px;
  }
  .icon-text2 {
    font-size: 15px;
  }
  .logo-row {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .header-row-last {
    margin-right: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .header-list {
    position: static;
    margin-top: 10px;
    width: 100%;
    background-color: none;
  }

  .icons-header {
    justify-content: space-around;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  .icons-header:hover {
    background-color: #cf7d08;
  }
}

@media only screen and (max-width: 1024px) {
  .header-row {
    flex-direction: column;
    padding: 10px;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .logo-row {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .header-row-last {
    margin-right: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .header-list {
    position: static;
    margin-top: 10px;
    width: 100%;
    background-color: none;
  }
  .icon-text {
    font-size: 17px;
  }
  .cartposition {
    position: absolute;
    margin-left: 50px;
    top: 30px;
    z-index: 1;
  }
  .icon-text2 {
    font-size: 15px;
  }
  .icons-header {
    justify-content: space-around;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  .icons-header:hover {
    background-color: #cf7d08;
  }
}

@media only screen and (max-width: 480px) {
  .search-row {
    width: 100%;
    margin: 0;
    padding: 8px 5px;
    flex-direction: row;
  }

  .search-row > input {
    width: 100%;
    font-size: small;
  }

  .header-container {
    align-items: center;
  }

  .header-row {
    width: 100%;
  }

  .header-list {
    width: 100%;
    justify-content: space-around;
    background-color: none;
  }

  .icons-header {
    justify-content: space-around;
    width: 100%;
    padding: 0px 0px;
  }
}

.navbar-custom {
  width: 100%;
}


.cart-icon-wrapper {
  position: relative;
  right: 10px;
  top: 100%;
 
  transform: translateY(-0%);
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 100; 
}


.nav-content-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.nav-content-wrapper .nav-link {
  margin-left: 5px;
}

.navbar-collapse {
  flex-grow: 1;
}
