/* General Swiper Styles */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-product-list {
  background: transparent;
  margin-top: 20px;
}

.feature-product-image {
  width: 300px;
  height: 300px;
  position: relative;
  display: inline-block;
}

.carousel-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.swiper-pagination-bullet-active {
  background-color: #06604e;
}

.to-be-launch-card {
  background-color: #06604e;
  color: white;
  font-weight: 800;
  font-size: 18px;
  width: 300px;
  top: 90%;
  z-index: 2;
  position: absolute;
  padding: 2px 0;
  transition: opacity 0.5s ease-in;
}

.feature-product-name {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .feature-product-image {
    width: 250px;
    height: 250px;
  }

  .feature-product-name {
    font-size: 16px;
  }

  .to-be-launch-card {
    width: 250px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .feature-product-image {
    width: 100%;
    height: auto;
  }

  .feature-product-name {
    font-size: 14px;
  }

  .to-be-launch-card {
    width: 100%;
    font-size: 12px;
  }
}
