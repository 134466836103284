.loading-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    width: 100vw; 
    background-color: #000;
    overflow: hidden; 
  }
  
  .loading-video-container video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    object-fit: cover; 
  }
  
  @media (max-width: 600px) {
    .loading-video-container {
      padding: 10px;
    }
  
    .loading-video-container video {
      width: 100%;
      height: auto; 
      border-radius: 5px; 
    }
  }
  
  @media (min-width: 600px) and (max-width: 1024px) {
    .loading-video-container {
      padding: 20px;
    }
  
    .loading-video-container video {
      width: 80%; 
      height: auto; 
    }
  }
  
  @media (min-width: 1024px) {
    .loading-video-container video {
      width: 60%; 
      height: auto; 
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    }
  }
  