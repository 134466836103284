.testimonial-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.testimonial-card-wrapper {
  flex: 1 1 calc(33.333% - 40px);
  max-width: calc(33.333% - 40px);
  margin: 10px;
  box-sizing: border-box;
}

.testimonial-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.card-up {
  height: 120px;
  overflow: hidden;
  background-color: #cf7d08;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.avatar {
  width: 110px;
  height: 110px;
  margin-top: -60px;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-body {
  padding: 20px;
}

.card-body h4,
.card-body p {
  color: black;
}

.dark-grey-text {
  color: #555;

  font-size: 1.2em;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.testimonial-image {
  margin-top: 10px;
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
}

.testimonial-video {
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .testimonial-card-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.testimonial-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
}

.testimonial-form div {
  margin-bottom: 15px;
}

.testimonial-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.testimonial-form input[type="text"],
.testimonial-form textarea,
.testimonial-form input[type="file"] {
  width: calc(100% - 10px);
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.testimonial-form textarea {
  height: 100px;
  resize: vertical;
}

.testimonial-form button {
  background-color: #cf7d08;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.testimonial-form button:hover {
  background-color: #b96e07;
}
